@import "node_modules/bulma/sass/utilities/initial-variables.sass";

@font-face {
  font-family: "Montserrat";
  font-weight: 400;
  src: local("Montserrat"),
    url(./lib/fonts/montserrat/Montserrat-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "Montserrat";
  font-weight: 600;
  src: local("Montserrat"),
    url(./lib/fonts/montserrat/Montserrat-SemiBold.ttf) format("truetype");
}
@font-face {
  font-family: "Montserrat";
  font-weight: 700;
  src: local("Montserrat"),
    url(./lib/fonts/montserrat/Montserrat-Bold.ttf) format("truetype");
}

$primary: $grey-darker;
$scheme-main: $yellow;
// $scheme-main: #ff6e57;

$text: $black-ter;
$border: $black-ter;
$border-hover: $black-bis;
$border-light: $grey-dark;
$border-light-hover: $grey-darker;

$family-sans-serif: "Montserrat", -apple-system, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  "Helvetica", "Arial", sans-serif;

$navbar-tabs-height: 4.5rem;

@import "node_modules/bulma/bulma.sass";
