@import "./variables";

html {
  overscroll-behavior: contain;
}

#root {
  max-width: 460px;
  margin: 0 auto;
}

.title,
.subtitle {
  width: 100%;
}

.input,
.textarea {
  background-color: white;
}

.select {
  width: 100%;

  select {
    width: 100%;
    background-color: white;
  }
}

.button {
  &:hover {
    border-color: $border-hover;
  }

  &:focus,
  &.is-focused {
    border-color: $black-bis;

    &:not(:active) {
      box-shadow: none;
    }
  }

  &:active,
  &.is-active {
    background-color: $grey-darker;
    color: $text-invert;
  }
}

.field {
  &.is-grouped {
    > .label:not(:last-child) {
      flex-grow: 1;
      margin-bottom: 0;
      align-self: center;
    }

    .input {
      width: 0;
      min-width: 0;
      flex-grow: 1;
    }
  }
}

.App {
  padding: 1rem;
}

.navbar {
  justify-content: center;
  border-top: 1px solid hsl(0, 0%, 33%);

  &-brand,
  &-tabs {
    height: $navbar-tabs-height;
  }

  .navbar-item {
    width: 25%;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
    font-size: 0.8rem;
    line-height: 200%;

    svg {
      stroke-width: 1;
    }

    &:hover,
    &:focus,
    &:active {
      color: $text;
      background-color: transparent;
    }

    &.is-active {
      background-color: transparent;
      color: inherit;
      font-weight: $weight-bold;

      svg {
        stroke-width: 2;
      }
    }

    &.is-disabled {
      cursor: default;
      opacity: 0.33;
    }
  }
}

.is-connecting {
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  height: 6px;
  background-color: $border-light;
}

.layout-center-v {
  display: flex;
  height: calc(100vh - #{$navbar-tabs-height});
  justify-content: center;
  align-items: center;
}
