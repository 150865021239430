@import "../../variables";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.profileTitle {
  color: $grey-dark;
  font-size: 2rem;
  font-weight: $weight-semibold;
  line-height: 1.125;
  text-align: center;
  margin-bottom: 0;
  min-height: 36px;
}

.group {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  margin: 2rem 0 1rem;
}

.dimension {
  width: 50%;
}

.dimensionHeading {
  font-size: 11px;
  letter-spacing: 0.05rem;
  margin-bottom: 5px;
  text-transform: uppercase;
  font-weight: $weight-bold;
  color: $grey-dark;
}

.avg {
  color: $grey-darker;
  font-size: 2rem;
  font-weight: $weight-semibold;
  line-height: 1.125;
}

.totals {
  margin-top: 5px;
  color: $grey-dark;
  font-size: 13px;
  font-weight: $weight-semibold;
}
